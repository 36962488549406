const zhHant = {
  home: {
    // HoneView.vue
    cancelSubscriptionSuccess: "你已經取消訂閱。",
    cancelSubscriptionFail: "取消訂閱失敗，請重試。",
    // BannerComponent.vue
    slideshowImg: "幻燈片圖片",
    title1: "西尼克斯",
    title2: "",
    subtitle1: "革新出行科技 建立智慧城市",
    subtitle2: "",
    knowMore: "了解更多",
    // IntroComponent.vue
    intro:
      "西尼克斯致力於創新科技，試圖將現時的最新技術整合在道路、運輸和汽車相關領域之中，為社會各個議題提供合適的解決方案，從而推動香港成為智慧城市。目前本公司擁有三大業務，分別是電動車充電方案、路面檢測方案和網約車平台。",
    buyNow: "立即購買",
    youtubeTitle: "YouTube影片播放器",
    detailsTitle: "選擇我們的理由",
    details1: "安全防護：具備安全防護功能，盡力保障你的安全。",
    details2: "價錢相宜：極高性價比，付出最低成本，也能滿足日常充電體驗。",
    details3: "方便使用：可連接 Wi-Fi 網絡，直接使用手機操控充電設備。",
    details4: "自主研發：專利產品，絕無虛假。",
    details5: "",
    // CancelSubscriptionComponent.vue
    cancelSubscriptionTitle1: "你確定取消訂閱",
    cancelSubscriptionTitle2: "我們的資訊嗎?",
    cancelSubscriptionConfirm: "確認",
  },
  aboutUs: {
    title: "西尼克斯",
    subtitle: "新能源 新科技 新未來",
    intro1Title: "公司業務",
    intro1:
      "西尼克斯致力於創新科技，試圖將現時的最新技術整合在道路、運輸和汽車相關領域之中，為社會各個議題提供合適的解決方案，從而推動香港成為智慧城市。目前本公司擁有三大業務，分別是電動車充電方案、路面檢測方案和網約車平台。",
    intro1_1Title: "電動車充電方案",
    intro1_1:
      "西尼克斯決意開拓電動車充電的全新模式，垂直整合充電解決方案，由實體充電站到手機客戶端，一應俱全，實現能源共享及有效使用，從而推動新能源產業的發展，使人人都能隨處充電、隨心充電。",
    intro1_2Title: "路面檢測方案",
    intro1_2:
      "西尼克斯希望革新香港的路面檢測方式，與時並進，引入自動化路面檢測方案，即時發現路面問題，提高道路使用者的駕駛體驗，並且運用大數據及人工智能，準確調控路面維修的時間和範圍，減低所需人手和成本。",
    intro1_3Title: "網約車平台",
    intro1_3:
      "汽車共享，是當今趨勢，可以充分運用汽車的空閒座位，減少資源浪費，實現可持續發展，符合本公司的一貫理念。有鑒於此，西尼克斯推出網約車業務，致力服務中港兩地居民，促進兩地互聯互通，實現汽車共享的初衷。",
    intro1_3Bottom: "gbataxi.com",
    intro2Title: "公司理念",
    intro2_1Title: "追求創新",
    intro2_1pt1: "思考創新科技和新能源產業的未來",
    intro2_1pt2: "為電動車輛提供另類的充電模式",
    intro2_1pt3: "改革目前的道路檢測模式",
    intro2_1pt4: "提供嶄新的網約車服務",
    intro2_2Title: "保障健康",
    intro2_2pt1: "開發穿戴防菌、防污染帽",
    intro2_2pt2: "以負離子及經HEPA過濾，直接於臉上形成隱形防護",
    intro2_2Bottom: "p360tec.com",
    intro2_3Title: "可持續發展",
    intro2_3pt1: "創造更完整的電動車生態圈",
    intro2_3pt2: "減少路面維護的成本",
    intro2_3pt3: "促進中港兩地的可持續交流",
    intro2_3pt4: "為社會和自然環境作出貢獻",
    intro3Title: "企業責任",
    intro3_1:
      "西尼克斯作為一間科學園的培育公司，不僅研發先進的科技產品，還希望藉此推動城市的逐步變革，實現智慧城市的構想。這是本公司的宏大理念，也是應有的社會責任。因此，本公司一直秉持務實的態度，因應城市和車主的實際需要而發展電動車充電業務。",
    intro3_2:
      "現時，電動車產業正在蓬勃發展，市場亟待一種可以快速普及的電動車充電設備，而本公司的充電插座和充電椿按照現有的電力系統設計，毋需額外鋪設電纜。除了減少大量不必要的安裝成本之外，亦減低資源消耗和去除繁瑣的安裝步驟，解決相關基建項目經常耗費甚巨和過程繁雜的問題。",
    intro3_3:
      "此外，本公司的產品切合車主的實際需要。城市之中，超過90%的車主平均一天不會行駛超過50公里，本公司的產品足以滿足日常所需，而且慢速充電有效減少電池損耗，變相延長電動車的壽命。某些城市實施浮動式電力收費，本公司的產品能夠設定在低價時段充電，為車主節省電費，更加為地區電網削峰填谷，達成雙贏。",
    intro3_4:
      "本公司期待自家產品能遍布城市各處，為民眾提供便利的充電設施，最終實現分布式充電的目標，即是人人皆可隨時隨地充電，人人皆可以相宜價錢充電，為環保，為國家，為自身，亦為民眾，謀取更大福祉。",
  },
  evCharging: {
    title: "電動車充電方案",
    subtitle: "",
    introTitle: "產品介紹",
    introContent:
      "Xinics EV雲平台可通過不同的ocpp汽車充電設備(7kw、14kw、44kw甚至DC 直流充電)，或10A或16A智能用電終端，及60A滑軌式智能開關等。本公司同時設計了一款萬能充電樁，集智能插座、智能開關、汽車充電槍於一體，並且結合了媒體傳播功能，為充電站實現計時、計電、計費和廣告收益的綜合解決方案。西尼克斯自家構建設計的大數據雲端平台具有用電管理、計費管理、統計分析等功能，尤其針對電容有限或有峰谷價差的充電站進行有序充電管理，實現不擴容用電，實現電網削峰填谷。現己成功開發H5網頁、微信小程序、手機app等不同版本。",
    ideaTitle: "設計理念",
    ideaContent1:
      "雖然電動車產業的興起是不可逆轉的大勢，但是電動車的相關配套略為缺乏，窒礙電動車產業的發展。西尼克斯的團隊深深知道，其相關配套的普及，必然不能依靠少數高端昂貴的產品。有見於此，本公司致力研發成本較低和適合大部份用戶的充電裝置。",
    ideaContent2:
      "舊有產品往往盲目追求性能上的成就，強調所謂的「快速充電」，而犠牲了產品的成本效益，亦忽視了廣大電動車使用者的普遍需求。快速充電需要更大的電壓及電流，其代價是大大降低了電池的壽命。電池是電動車的靈魂，誰不想自己車輛的電池更耐用？",
    ideaContent3:
      "事實上，世界主要城市的94%電動車車主單日行駛不超過50公里。按照每百公里20kwh耗電量推算，平均每天消耗電量10kwh，使用16A Level 2交流充電樁僅需要1.5個小時即可充滿所需電量，西尼克斯的產品正因此而生。我們不作多餘的浪費，亦不願意以誇張的行銷術語欺騙消費者。西尼克斯希望，人人都能以相宜的價錢獲得我們的產品，而我們的產品亦能為購買者創造持續的收益，藉此推廣共享電源的概念，探索可持續發展的充電模式。",
    solutionTitle: "一站式充電方案",
    device: "充電裝置",
    deviceDescription:
      "西尼克斯認為充電裝置是整個充電方案的核心，所以相當重視充電裝置的研發，確保充電裝置的安全性及可擴展性，保證商戶和用戶都能安心使用、隨心使用。",
    device1Title: "安全防護，多層保護",
    device1Content:
      "具備溫度監控、電流監控、電壓監控，多層監控確保用電過程安全。",
    device2Title: "計量芯片，精準計價",
    device2Content:
      "集成計量芯片，實時計算用電功率，實現電量精準計量，同時支持電量和時間雙重計量。",
    device3Title: "ZigBee / SIM連接網絡",
    device3Content:
      "基於Wi-Fi /Sim模組，以雲端平台連接物聯網，實現24小時不間斷實時監控。",
    client: "商戶",
    clientDescription:
      "西尼克斯的充電方案為商戶提供高度整合的管理平台，同時保證了平台的方便性和自由度。商戶可以統一管理充電裝置，直接設定價錢、電力管理方案、用戶分流選項等等。此外，商戶更可因應自身需要投放廣告和定制用戶介面。",
    client1Title: "成本低廉",
    client1Content1: "價錢僅為其他品牌同類產品的1 / 20 *。",
    client1Content2: "採用網上平台收費，無需增加額外人手。",
    client2Title: "安裝方便",
    client2Content:
      "不需更改舊有的供電系統。商戶可設定最大供電量，確保不會超出供電負荷。",
    client3Title: "快速收支平衡",
    client3Content: "預計只需6-9 個月就能達到收支平衡*。",
    client4Title: "自由定價",
    client4Content: "按充電量或時間設定價錢，更可按照不同時段設定價錢。",
    client5Title: "VIP管理",
    client5Content: "可劃分用戶類別，按用戶類別給予不同定價、優惠或其他功能。",
    client6Title: "智能電力管理",
    client6Content:
      "設定最大供電量，超出既定供電量時，充電裝置自動開啟排隊模式。",
    client7Title: "雲端監察",
    client7Content1: "一個帳號，一個平台，即可管理所有裝置。",
    client7Content2: "統一收集各項數據，供商戶查閱和分析。",
    client8Title: "定制開發",
    client8Content1: "可嵌入自家品牌圖示。",
    client8Content2: "可定制開發更多功能，詳情請向我們查詢。",
    user: "用戶",
    userDescription:
      "西尼克斯一直關切用戶感受，致力改善用戶體驗。只需幾個按鍵，便可完成支付，以及管理充電模式，更可隨時翻查相關紀錄，了解自己的充電習慣。",
    user1Title: "自助繳費",
    user1Content: "無需登錄註冊，掃描QR Code即可付費，支援微信支付和支付寶。",
    user2Title: "實時監控",
    user2Content:
      "實時監控充電狀態，包括電流、電壓、電量、時間、電動車電池狀態等等。",
    user3Title: "紀錄追蹤",
    user3Content: "通過APP查閱充電紀綠和其他資料。",
    user4Title: "遙距操作",
    user4Content: "可利用手機設置電源開關的條件，毋須手動拔插電源，省時省力。",
  },
  gbaTaxi: {
    title: "網約車平台",
    subtitle: "",
    introTitle: "平台介紹",
    introContent:
      "近年，共享經濟的風潮席卷世界，而共享汽車業務於各地亦大獲成功。本公司作為後起之秀，有感現時共享汽車業務尚有改進空間，意欲進入相關領域，革新網約車的服務模式。同時，隨著中央政府把粵港澳大灣區視為重大發展戰略，提倡深化粵港澳合作，綜合三地優勢，為國家的創新發展和改革開放作出貢獻，本公司希望把握機會，為國家發展戰略出一分力，藉助網約車平台促進三地居民互聯互通，加強三地的商業和民間來往，從而加快三地融合，為三地居民提供最合適的服務。網約車平台不僅是一個平台，還是改善道路交通和促進人民交流的重大渠道。",
    processTitle: "項目進程",
    processContent: "敬請期待。",
  },
  roadInspection: {
    title: "路面檢測方案",
    subtitle: "",
    introTitle: "方案介紹",
    introContent1:
      "本公司的路面檢測方案採用流動感應技術，實時上傳路面數據至雲端，再藉由雲端的運算力量分析數據，偵測路面狀況的好壞，從而聯絡相關部門跟進處理。此方案銳意改革現時的路面檢測模式，配合「智慧出行」方略，改善現時的運輸系統，推動香港成為智慧城市。",
    introContent2:
      "「智慧出行」路線圖相當強調「智能運輸基礎建設」一項。所謂工欲善其事，必先利其器，若無基礎建設，數據分析無從談起，更遑論製作應用程式和提供服務。然而，基礎設施耗費甚大，工時太長。目前，運輸署已在主要道路安裝1200組交通探測器，又進行「多功能智慧燈柱」試驗計劃，成效巨大，可惜成本同時巨大。前述的基礎建設都屬於感應和分析技術的範疇。本公司的流動感應技術毋須在固定地點安裝傳感或探測器，直接以流動形式探測道路。即使道路偏僻遙遠，感應儀器亦可到達，大大減低基礎設施的建設成本。過去，因為成本效益不彰，所以不少道路不會增設感應設備。流動感應技術便可徹底解決這個問題，大幅增加路面檢測的覆蓋率，進一步維護路面的安全。",
    introContent3:
      "「智慧出行」以安全作為其中一項願景，本公司方案正正能夠最大程度提升道路的安全。本公司不僅減省路面損壞的通報程序，而且能夠防患於未然。本公司的探測儀器能夠深入路面之下，洞察尚未暴露在外的禍患。即使禍患尚未形成，人工智能及數據分析能夠依據儀器的各項數據計算潛在的危機，通知相關人員盡快處理。",
    introContent4:
      "人工智能和數據分析，不是僅僅能夠發現區區數個潛在危機，還能夠徹底了解香港道路網絡的整體情況，和繪製香港路面狀況地圖。以往，實現這些瘋狂的想法，需要的人手和支出難以想像。現在，憑藉本方案便可實現。本方案的數據不只用於路面檢測和維護，更可能幫助未來道路的規劃，甚至協助改進鋪設道路的物料，極大改善現時香港道路管理的慣常做法。",
    introContent5:
      "本方案意圖運用新型感應及數據收集方式，再配合人工智能和大數據分析，革新當前的路面維護方法。一旦成功，香港將領先全球絕大多數城市，亦向「智慧城市」邁出一大步。",
    futureTitle: "展望未來",
    futureContent1:
      "創新科技已經成為改善運輸建設的重中之重。有見及此，香港政府在2001年發布首份智能運輸系統策略及發展計劃。隨著創新科技發展愈趨迅速，香港政府近年公布《香港智慧城市藍圖2.0》，試圖為香港的城市革新訂立整體規劃，其中一項重點便是「智慧出行」。",
    futureContent2:
      "「智慧出行」計劃訂立三大策略，試圖從三個策略層面將現時的最新技術整合於運輸系統之中，包括「智能運輸基礎建設」（Smart Transport Infrastructure）、「數據共享和分析」（Data Sharing and Analytics）和「應用和服務」（Applications and Services）。三大策略環環緊扣，互相依賴，缺一不可，「智能運輸基礎建設」是後兩者的必要條件，基礎建設的電子設置收集大數據，藉此實現「數據共享和分析」，其成果得以「應用和服務」在政府部門或道路使用者身上。該計劃還希望實現五大願景，包括安全（Safe）、資訊（Informative）、綠色（Green）、高流通性（Mobile）和便捷（Accessible），合稱「SIGMA」。三大策略是執行手段，五大願景是達成效果，互相配合，方是「智慧出行」的完整內容。",
    processTitle: "項目進程",
    processContent: "目前本公司已向政府提交方案，相信不久便能成事。敬請期待。",
  },
  product: {
    // ProductView.vue
    company: "西尼克斯",
    // GoToShoppingCartComponent.vue
    goToShoppingCartConfirm: "前往購物車",
    // PaymentConirmComponent.vue
    purchaseConfirm: "確認",
    // ProductComponent.vue
    carouselAlt: "幻燈片圖片",
    carouselPrevious: "上一張",
    carouselNext: "下一張",
    currencyCode: "",
    share: " 分享",
    colour: "顏色：",
    outOfStock: "已售罄",
    quantity: "產品數量",
    pieces: "件",
    purchase: "立即購買",
    addToShoppingCart: "加入購物車",
    details: "特色",
    specs: "規格",
    manual: "支援",
    manualImgText: "用戶指南",
    manualDownload: "按此下載",
    interest: "你可能感興趣的產品",
    outOfStockMsg:
      "此產品處於預售階段，請點擊<a href='/'>這裡</a>了解更多詳情!",
    // ProductInfoComponent.vue
    unitPrice: "單價：",
    totalPrice: "總價：",
    quantity2: "數量：",
    // ShareComponent.vue
    clipboardHint: "點擊複製鏈結",
    ogDescription: "立即購買!",
    copiedHint: "複製成功",
    whatsappAlt: "分享這件產品到 Whatsapp",
    facebookAlt: "分享這件產品到 Facebook",
    twitterAlt: "分享這件產品到 Twitter",
  },
  shoppingCart: {
    // ShoppingCartView.vue
    voucherCodeShortText: "優惠碼",
    voucherCodeLongText: "輸入優惠碼",
    title: "購物車",
    currencyCode: "@:product.currencyCode",
    pieces: "@:product.pieces",
    noProduct: "沒有產品在購物車中。",
    subTotal: "總計",
    voucherCode: "優惠碼",
    checkOut: "結帳",
  },
  trackNTrace: {
    // CheckOrderView.vue
    formTitle: "訂單追蹤",
    formText: "請輸你的電郵和訂單編號",
    email: "電郵：",
    emailPlaceholder: "你結帳時使用的電郵",
    invalidEmail: "無效電郵",
    orderNumber: "訂單編號：",
    orderNumberPlaceholder: "訂單編號",
    invalidOrderNumber: "無效訂單編號",
    formConfirm: "確認",
    // OrderStatusView.vue
    productPhoto: "產品圖片",
    orderId: "訂單 ID",
    datePaid: "付款日期",
    shippingAddress: "運送至",
    shippingStatusOrdered: "已下單",
    shippingStatusShipped: "已發貨",
    shippingStatusOutOfDelivery: "運送中",
    shippingStatusArrived: "已到達",
  },
  footer: {
    // FooterComponent.vue
    subscriptionSuccess: "你已經成功訂閱我們的資訊!",
    subscriptionFailed: "訂閱失敗，請重試。",
    emailSend: "發送",
    emailLoading: "發送中...",
    validEmail: "成功!",
    invalidEmail: "無效電郵或訊息。",
    subscriptionTitle: "訂閱西尼克斯電子報",
    subscriptionText: "直接從西尼克斯電子報接收獨家優惠和新產品。",
    subscriptionRegister: "以電郵訂閱",
    subscriptionGoogle: "以 Google 帳號訂閱",
    socialMediaTitle: "關注我們 | 社交媒體",
    socialMediaFacebook: "Facebook 專頁",
    socialMediaInstagram: "Instagram 專頁",
    socialMediaYoutube: "Youtube 頻道",
    contactUs: "聯絡我們",
    emailAddressPlaceholder: "輸入你的電郵...",
    emailContentPlaceholder: "留下你的訊息...",
    tel: "電話",
    email: "電郵",
    address: "地址",
    hkaddress: "香港科學園第一期2W座3樓02室",
    copyright1: "版權所有",
    copyright2: "，不得轉載。",
  },
  header: {
    // HeaderComponent.vue
    buyNow: "立即購買",
    shoppingCart: "購物車",
    orderTracking: "訂單追蹤",
    aboutUs: "關於我們",
    evCharging: "電動車充電方案",
    gbaTaxi: "網約車平台",
    roadInspection: "路面檢測方案",
    goToCategory: "返回上一頁",
  },
  shippingAddressForm: {
    // ShippingAddressComponent.vue
    title1: "貨運地址",
    name: "名字：",
    email: "電郵：",
    line1: "詳細地址（第一行）：",
    line2: "詳細地址（第二行）：",
    city: "城市：",
    state: "州／省：",
    country: "國家：",
    postalCode: "郵政編碼：",
    hint1: "* 必填",
    hint2: '^ 如果你不清楚郵政編號或所在地區沒有郵政編號，填寫 "000000"',
    nextStep: "下一步",
    title2: "運送至",
    currencyCode: "@:product.currencyCode",
    previousStep: "上一步",
    submitLoading: "確認中...",
    submitNormal: "確認",
    require: "（必填）",
    emailNotValid: "（電郵格式無效）",
    serverError: "（伺服器錯誤，請重試。）",
  },
  payment: {
    // StripeComponent.vue
    unexpectedError: "出現未知錯誤。",
    paymentStatusSucceeded: "付款成功!",
    paymentStatusProcessing: "你的付款正在處理中",
    paymentStatusRequires: "付款失敗，請重試。",
    paymentStatusError: "出現錯誤!",
    currencyCode: "@:product.currencyCode",
    totalPrice: "總價：",
    stripeLoading: "請等等...",
    stripePayNow: "立即付款",
    // PaymentFinishedComponent.vue
    paymentFinishedTitle: "感謝你購買我們的產品!",
    paymentFinishedId: "你的訂單編號是",
    paymentFinishedReceipt: "收據稍後就會寄送至 {receiptEmail}",
    goShopping: "前往購物",
    saveAsImg: "以圖片格式保存資訊",
    tnt: "追蹤你的訂單",
    paymentNotFinished: "請重試。",
  },
  subscription: {
    // SubscriptionComponent.vue
    formTitle: "訂閱電子報",
    formName: "你的名字：",
    formEmail: "你的電郵：",
    formSubmit: "確認",
  },
};

const zhHantNumberFormats = {
  currency: {
    style: "currency",
    currency: "HKD",
    currencyDisplay: "symbol",
    useGrouping: true,
  },
};

export { zhHant, zhHantNumberFormats };
